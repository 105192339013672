/* Wait until document is loaded */
document.addEventListener('DOMContentLoaded', function () {
    /* Self invoking function - encapsulation */
    (function () {

        /* =============================== */
        /* === Start writing code here === */
        /* =============================== */

        // Set variables
        let previousScrollPosition = window.scrollX;
        let nav = document.getElementById('header--main');
        let navHeight = nav.offsetHeight;

        // Listen for scroll event
        window.addEventListener('scroll', function () {
            let currentScrollPosition = window.scrollY;
            if (previousScrollPosition > currentScrollPosition) {
                nav.style.top = "0";
            } else {
                nav.style.top = "-" + navHeight + "px";
            }
            previousScrollPosition = currentScrollPosition;
        });

        window.addEventListener('resize', function () {
            recalculateNavHeight();
        });

        function recalculateNavHeight() {
            navHeight = nav.offsetHeight;
        }

        /* ============================== */
        /* === Stop writing code here === */
        /* ============================== */

    })();
});
